<template>
	<div>
		<vs-prompt
      @close="$emit('closeOpenText')"
			class="open-text-poll"
      title="Open Text Poll"
      :disabled-outside="true"
      :buttons-hidden="true"
      :active.sync="isModalActiveLocal">
      <!-- question section -->
      <div class="p-4">
        <div class="w-full relative">
          <label class="text-secondary">Question</label>
          <vs-textarea
              class="w-full mt-2"
              name="item-content"
              rows="4"
              v-model="dataName"
          />
        </div>
        <div class="w-full relative">
          <div class="">
            <label class="text-secondary">Settings</label>
            <div class="mt-4 flex justify-between items-center">
                <div class="font-bold text-lg">
                    <div>{{ $t('Show Respondent Names') }}</div>
                    <div class="font-normal text-small text-secondary">{{ $t('Show participants name (if not Anonymous)') }}.</div>
                </div>
                <div class=" ml-6"><vs-switch @click.stop="" color="success" v-model="dataIsShowParticipant"/></div>
            </div>
            <div class="mt-4 flex justify-between items-center">
                <div class="font-bold text-lg">
                    <div>{{ $t('Hide live result') }}</div>
                    <div class="font-normal text-small text-secondary">{{ $t('Do not show result result after vote') }}.</div>
                </div>
                <div class=" ml-6"><vs-switch @click.stop="" color="success" v-model="dataIsHideLive"/></div>
            </div>
          </div>
        </div>
      </div>
      <!-- button section -->
      <div class="absolute bottom-0 right-0 mr-10 flex justify-end mb-6">
        <vs-button
            v-if="dataIdPoll"
            class="px-6 py-2 mr-4 pl-12"
            color="green"
            @click="togglePublish(selectedItem)">
            <div class="flex items-center relative">
                <svg class="w-6 absolute left-0" style="margin-left: -24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>play</title><path fill="currentColor" d="M8,5.14V19.14L19,12.14L8,5.14Z" /></svg>
                {{ $t('Launch') }}
            </div>
        </vs-button>
        <vs-button
          :disabled="!isFormValid"
          class="px-8 py-2"
          @click="submitData()">
          <div class="flex items-center">
              {{ $t('Save') }}
          </div>
        </vs-button>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import votingsApi from '@/api/voting';
import {
  getAxiosErrorMessage,
} from '@/lib/helper';

export default {
  components: {
  },
  props: {
    isShowOpenText: {
      type: Boolean,
      default: () => false,
    },
    selectedItem: {
      type: Object,
      default: () => null,
    },
    eventId: {
      type: Number,
      default: () => 0,
    },
  },
  watch: {
    isShowOpenText() {
      if (this.isShowOpenText) {
        this.isModalActiveLocal = true;
        if (this.selectedItem) {
          this.dataIdPoll = this.selectedItem.id;
          this.dataName = this.selectedItem.name;
          this.dataIsHideLive = this.selectedItem.is_hide_live_result;
          this.dataIsShowParticipant = this.selectedItem.is_show_respondent_name;
          this.dataIsMultipleAnswer = this.selectedItem.is_multiple_options;
          this.dataType = 'open_text';
        }
      } else {
        this.isModalActiveLocal = false;
        this.dataIdPoll = null;
        this.dataName = '';
        this.dataIsHideLive = false;
        this.dataIsMultipleAnswer = false;
        this.dataIsShowParticipant = false;
        this.dataType = 'open_text';
      }
    },
    isModalActiveLocal() {
      if (!this.isModalActiveLocal) this.$emit('closeOpenText');
    },
  },
  data() {
    return {
      isModalActiveLocal: false,
      dataIdPoll: null,
      dataName: '',
      dataIsHideLive: false,
      dataIsMultipleAnswer: false,
      dataIsShowParticipant: false,
      dataType: 'open_text',
    };
  },
  computed: {
    isFormValid() {
      return this.dataName !== '';
    },
    dragOptions() {
      return {
        animation: 100,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
  methods: {
    submitData() {
      this.$vs.loading();
      const params = {
        name: this.dataName,
        is_hide_live_result: this.dataIsHideLive,
        is_multiple_options: this.dataIsMultipleAnswer,
        is_show_respondent_name: this.dataIsShowParticipant,
        type: this.dataType,
      };
      const callback = () => {
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Poll'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      if (!this.dataIdPoll) votingsApi.create(this.eventId ? this.eventId : null, params, callback, errorCallback);
      if (this.dataIdPoll) votingsApi.update(this.dataIdPoll ? this.dataIdPoll : null, params, callback, errorCallback);
    },
    onAnswerChange() {
    },
    togglePublish(poll) {
      this.$emit('togglePublish', poll);
      this.isModalActiveLocal = false;
    },
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
<style lang="scss">
	.open-text-poll.con-vs-dialog .vs-dialog {
    min-width: 400px!important;
    min-height: 600px!important;
    position: relative;
      @media (min-width: 600px) {
          min-width: 600px!important;
      }
  }

  .answer-input {
    .vs-input--input {
      border: none!important; /* Remove the default border */
      border-bottom: 1px solid #ccc!important; /* Add a bottom border */
      border-radius: 0; /* Remove border radius if needed */
      padding: 5px; /* Adjust padding as needed */
      background-color: transparent; /* Make the background transparent */
      width: 100%; /* Optional: Set the width to 100% */

      &:focus {
        border: none!important; /* Remove the default border */
        border-bottom: 1px solid #ccc!important; /* Add a bottom border */
        border-radius: 0; /* Remove border radius if needed */
        padding: 5px; /* Adjust padding as needed */
        background-color: transparent; /* Make the background transparent */
        width: 100%; /* Optional: Set the width to 100% */
      }
    }
  }

</style>

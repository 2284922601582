<template>
	<div>
    <div class="pt-2">
      <div class="flex justify-between items-center mb-6">
        <div class="font-bold text-lg">{{ $t('Live') }}</div>
        <div class="flex items-center">
          <div style="height: 44px;"></div>
			</div>
      </div>
    </div>
    <div>
      <ResultsPoll :eventData="eventData" :livePoll="livePoll"></ResultsPoll>
    </div>
  </div>
</template>

<script>
import votingsApi from '@/api/voting';
import { getAxiosErrorMessage } from '@/lib/helper';
import ResultsPoll from '@/components/polls/ResultsPoll.vue';

export default {
  components: {
    ResultsPoll,
  },
  props: {
    eventData: {
      type: Object,
      default: () => {},
    },
  },
  sockets: {
    votings_add(voting) {
      this.updateVoting(voting);
    },
    votings_update(voting) {
      this.fetchActive();
      this.updateVoting(voting);
    },
    votings_active() {
      this.fetchActive();
    },
    votings_stop() {
      this.fetchActive();
    },
    votings_results(voting) {
      this.updateVoting(voting);
    },
  },
  data() {
    return {
      page: 1,
      totalPage: null,
      poll: null,
      livePoll: null,
    };
  },
  computed: {
  },
  methods: {
    resetAll() {
      this.poll = null;
      this.livePoll = null;
    },
    fetchActive(reset = true) {
      if (reset) this.poll = null;
      this.$vs.loading();
      const callback = (response) => {
        this.livePoll = response.data;
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        if (message !== 'No active vote' && message !== 'Ingen aktiv avstemning') {
          this.$vs.notify({
            title: this.$t('Voting'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
        }
        this.livePoll = null;
      };
      votingsApi.getActive(this.eventData && this.eventData.id ? this.eventData.id : null, callback, errorCallback);
    },
    updateVoting(item) {
      if (this.livePoll && item && this.livePoll.id === item.id) {
        this.livePoll = item;
      }
    },
  },
  mounted() {
    this.fetchActive();
  },
  created() {
  },
  destroyed() {
  },
};
</script>

<style lang="scss">

</style>

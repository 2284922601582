<template>
	<div>
    <div v-for="poll in polls" :key="poll.id" class="item-poll shadow" @mouseover="onMouseOver(poll)" @mouseleave="onMouseLeave()">
      <!-- header -->
      <div class="flex items-center justify-between">
        <div>
          <div class="text-bold">{{ poll && poll.type === 'multiple_choice' && !poll.is_multiple_options ? 'Multiple Option - Single Choice' : ''}}</div>
          <div class="text-bold">{{ poll && poll.type === 'multiple_choice' && poll.is_multiple_options ? 'Multiple Option - Multiple Choice' : ''}}</div>
          <div class="text-bold">{{ poll && poll.type === 'open_text' ? 'Open Text' : '' }}</div>
          <div class="text-sm text-secondary">{{ $t('Votes') }}: {{ poll && poll.meta && poll.meta.total_votes ? poll.meta.total_votes : '-' }}</div>
        </div>
        <!-- menu more button -->
        <div class="flex items-center">
          <div v-if="isHover && hoveringItem && hoveringItem.id === poll.id" class="flex items-center">
            <!-- lock -->
            <vs-button v-tooltip="poll && !poll.is_locked ? $t('Lock Polling') : $t('Unlock Polling')" @click="lockVote(poll)" class="ml-2 btn-icon px-2 py-1" color="secondary" type="border">
              <svg v-if="poll && poll.is_locked" class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" />
              </svg>
              <svg v-else class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>lock-open</title><path d="M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V10A2,2 0 0,1 6,8H15V6A3,3 0 0,0 12,3A3,3 0 0,0 9,6H7A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,17A2,2 0 0,0 14,15A2,2 0 0,0 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17Z" /></svg>
            </vs-button>
            <!-- view -->
            <vs-button v-tooltip="poll && !poll.is_hide_live_result ? $t('Hide Result') : $t('Show Result')" @click="toggleView(poll)" class="ml-2 btn-icon px-2 py-1" color="secondary" type="border">
              <svg v-if="poll && !poll.is_hide_live_result" class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>eye</title><path d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z" /></svg>
              <svg v-else class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>eye-off</title><path d="M11.83,9L15,12.16C15,12.11 15,12.05 15,12A3,3 0 0,0 12,9C11.94,9 11.89,9 11.83,9M7.53,9.8L9.08,11.35C9.03,11.56 9,11.77 9,12A3,3 0 0,0 12,15C12.22,15 12.44,14.97 12.65,14.92L14.2,16.47C13.53,16.8 12.79,17 12,17A5,5 0 0,1 7,12C7,11.21 7.2,10.47 7.53,9.8M2,4.27L4.28,6.55L4.73,7C3.08,8.3 1.78,10 1,12C2.73,16.39 7,19.5 12,19.5C13.55,19.5 15.03,19.2 16.38,18.66L16.81,19.08L19.73,22L21,20.73L3.27,3M12,7A5,5 0 0,1 17,12C17,12.64 16.87,13.26 16.64,13.82L19.57,16.75C21.07,15.5 22.27,13.86 23,12C21.27,7.61 17,4.5 12,4.5C10.6,4.5 9.26,4.75 8,5.2L10.17,7.35C10.74,7.13 11.35,7 12,7Z" /></svg>
            </vs-button>
            <!-- toggle live -->
            <vs-button v-tooltip="poll && !poll.is_published ? $t('Start This Polling') : $t('Stop This Polling')" @click="togglePublish(poll)" class="ml-2 btn-icon px-2 py-1" :color="poll && poll.is_published ? 'red' : 'green'">
              <svg v-if="poll && !poll.is_published" style="width: 22px; padding-top: 1px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>play</title><path fill="currentColor" d="M8,5.14V19.14L19,12.14L8,5.14Z" /></svg>
              <svg v-else style="width: 20px; padding: 3px 1px 2px 1px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>square</title><path fill="currentColor" d="M3,3V21H21V3" /></svg>
            </vs-button>
          </div>
          <div>
            <vs-dropdown class="dd-actions cursor-pointer" vs-trigger-click>
              <div>
                <feather-icon  icon="MoreVerticalIcon" svgClasses="h-7 w-7" />
              </div>
              <vs-dropdown-menu>
                <!-- Edit -->
                <vs-dropdown-item :disabled="poll && poll.is_published ? true : false">
                  <div @click="editPoll(poll)" class="flex items-center moderation-menu">
                    <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>pencil</title><path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" /></svg></div>
                    <div class="ml-2">{{ $t('Edit') }}</div>
                  </div>
                </vs-dropdown-item>
                <!-- Duplicate -->
                <vs-dropdown-item>
                  <div @click="duplicatePoll(poll)" class="flex items-center moderation-menu">
                    <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>content-duplicate</title><path d="M11,17H4A2,2 0 0,1 2,15V3A2,2 0 0,1 4,1H16V3H4V15H11V13L15,16L11,19V17M19,21V7H8V13H6V7A2,2 0 0,1 8,5H19A2,2 0 0,1 21,7V21A2,2 0 0,1 19,23H8A2,2 0 0,1 6,21V19H8V21H19Z" /></svg></div>
                    <div class="ml-2">{{ $t('Duplicate') }}</div>
                  </div>
                </vs-dropdown-item>
                <!-- Share -->
                <vs-dropdown-item class="">
                  <div @click="sharePoll(poll)" class="flex items-center moderation-menu">
                    <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>share-variant</title><path d="M18,16.08C17.24,16.08 16.56,16.38 16.04,16.85L8.91,12.7C8.96,12.47 9,12.24 9,12C9,11.76 8.96,11.53 8.91,11.3L15.96,7.19C16.5,7.69 17.21,8 18,8A3,3 0 0,0 21,5A3,3 0 0,0 18,2A3,3 0 0,0 15,5C15,5.24 15.04,5.47 15.09,5.7L8.04,9.81C7.5,9.31 6.79,9 6,9A3,3 0 0,0 3,12A3,3 0 0,0 6,15C6.79,15 7.5,14.69 8.04,14.19L15.16,18.34C15.11,18.55 15.08,18.77 15.08,19C15.08,20.61 16.39,21.91 18,21.91C19.61,21.91 20.92,20.61 20.92,19A2.92,2.92 0 0,0 18,16.08Z" /></svg></div>
                    <div class="ml-2">{{ $t('Share') }}</div>
                  </div>
                </vs-dropdown-item>
                <vs-dropdown-item>
                  <div @click="openExport(poll)" class="flex items-center moderation-menu">
                    <svg class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>export</title><path fill="currentColor" d="M23,12L19,8V11H10V13H19V16M1,18V6C1,4.89 1.9,4 3,4H15A2,2 0 0,1 17,6V9H15V6H3V18H15V15H17V18A2,2 0 0,1 15,20H3A2,2 0 0,1 1,18Z" /></svg>
                    <div class="ml-2">{{ $t('Export') }}</div>
                  </div>
                </vs-dropdown-item>
                <vs-divider class="p-0 m-0"></vs-divider>
                <!-- ViewResult -->
                <vs-dropdown-item>
                  <div @click="viewResultPoll(poll)" class="flex items-center moderation-menu">
                    <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>poll</title><path d="M3,22V8H7V22H3M10,22V2H14V22H10M17,22V14H21V22H17Z" /></svg></div>
                    <div class="ml-2">{{ $t('View Result') }}</div>
                  </div>
                </vs-dropdown-item>
                <!-- Reset Result -->
                <vs-dropdown-item :disabled="poll && poll.is_published ? true : false">
                  <div @click="resetPoll(poll)" class="flex items-center moderation-menu">
                    <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>undo-variant</title><path d="M13.5,7A6.5,6.5 0 0,1 20,13.5A6.5,6.5 0 0,1 13.5,20H10V18H13.5C16,18 18,16 18,13.5C18,11 16,9 13.5,9H7.83L10.91,12.09L9.5,13.5L4,8L9.5,2.5L10.92,3.91L7.83,7H13.5M6,18H8V20H6V18Z" /></svg></div>
                    <div class="ml-2">{{ $t('Reset Result') }}</div>
                  </div>
                </vs-dropdown-item>
                <vs-divider class="p-0 m-0"></vs-divider>
                <!-- Delete -->
                <vs-dropdown-item :disabled="poll && poll.is_published ? true : false">
                  <div @click="deletePoll(poll)" class="flex items-center moderation-menu">
                    <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>delete</title><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" /></svg></div>
                    <div class="ml-2">{{ $t('Delete') }}</div>
                  </div>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>
      </div>
      <!-- content -->
      <div class="mt-4">
        <div class="text-bold">{{ poll && poll.name ? poll.name : 'Voting Description' }}</div>
      </div>
    </div>
    <ModalSharePoll :eventData="eventData" :isShowModalSharePoll="isShowModalSharePoll" :selectedItem="selectedItem" @closeModalSharePoll="closeModalSharePoll"></ModalSharePoll>
    <!-- result -->
    <ResultsPollModal :eventData="eventData" :isShowModalResultsPoll="isShowModalResultsPoll" :poll="selectedItem" @closeModalResultsPoll="closeModalResultsPoll" @editPoll="editPoll"></ResultsPollModal>

    <!-- export item -->
		<vs-popup
			style="color:rgb(255,255,255);"
			background-color="rgba(255,255,255,.6)"
			:title="$t('Export')"
			:active.sync="listExport">
			<p class="text-base text-black mb-5">
				{{ $t('Select the file format for the output data') }}
			</p>
			<div class="flex justify-between w-full">
        <vs-button class="w-full m-2 px-2" :class="{ 'is-loading': isExporting }" @click="exportPolls('excel')">
          <div class="flex items-center">
            <svg class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>file-excel</title><path fill="currentColor" d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M15.8,20H14L12,16.6L10,20H8.2L11.1,15.5L8.2,11H10L12,14.4L14,11H15.8L12.9,15.5L15.8,20M13,9V3.5L18.5,9H13Z" /></svg>
            <div class="ml-2 text-base">{{ $t('Excel File') }}</div>
          </div>
        </vs-button>
        <!-- <vs-button class="w-full m-2 px-2" :class="{ 'is-loading': isExporting }" @click="exportPolls('doc')">
          <div class="flex items-center w-full">
            <svg class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>file-word</title><path fill="currentColor" d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M15.2,20H13.8L12,13.2L10.2,20H8.8L6.6,11H8.1L9.5,17.8L11.3,11H12.6L14.4,17.8L15.8,11H17.3L15.2,20M13,9V3.5L18.5,9H13Z" /></svg>
            <div class="ml-2 text-base">{{ $t('Text Document') }}</div>
          </div>
        </vs-button> -->
			</div>
		</vs-popup>
  </div>
</template>

<script>
import votingsApi from '@/api/voting';
import ResultsPollModal from '@/components/polls/ResultsPollModal.vue';
import ModalSharePoll from '@/components/polls/ModalSharePoll.vue';
import {
  getAxiosErrorMessage,
  duplicateVar,
} from '@/lib/helper';


export default {
  components: {
    ModalSharePoll,
    ResultsPollModal,
  },
  props: {
    polls: {
      type: Array,
      default: () => [],
    },
    eventData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isHover: false,
      hoveringItem: null,
      isShowModalSharePoll: false,
      selectedItem: null,
      isShowModalResultsPoll: false,
      listExport: false,
      isExporting: false,
    };
  },
  computed: {
  },
  methods: {
    onMouseOver(item) {
      this.isHover = true;
      this.hoveringItem = item;
    },
    onMouseLeave() {
      this.isHover = false;
      this.hoveringItem = null;
    },
    closeModalSharePoll() {
      this.isShowModalSharePoll = false;
    },
    closeMultipleChoice() {
      this.$emit('closeMultipleChoice');
    },
    lockVote(poll) {
      this.$vs.loading();
      const params = {
        is_locked: !poll.is_locked,
      };
      const callback = (response) => {
        this.$emit('updateVoting', response.data);
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Poll'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      votingsApi.update(poll.id ? poll.id : null, params, callback, errorCallback);
    },
    toggleView(poll) {
      this.$vs.loading();
      const params = {
        is_hide_live_result: !poll.is_hide_live_result,
      };
      const callback = (response) => {
        this.$emit('updateVoting', response.data);
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Poll'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      votingsApi.update(poll.id ? poll.id : null, params, callback, errorCallback);
    },
    togglePublish(poll) {
      this.$emit('togglePublish', poll);
    },
    editPoll(poll) {
      this.$emit('editMultipleChoice', poll);
    },
    duplicatePoll(poll) {
      this.$vs.loading();
      const callback = () => {
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Voting'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      votingsApi.duplicate(poll && poll.id ? poll.id : null, callback, errorCallback);
    },
    sharePoll(poll) {
      this.selectedItem = poll;
      this.isShowModalSharePoll = true;
    },
    viewResultPoll(poll) {
      this.selectedItem = poll;
      this.isShowModalResultsPoll = true;
    },
    closeModalResultsPoll() {
      this.selectedItem = null;
      this.isShowModalResultsPoll = false;
    },
    openExport(poll) {
      this.selectedItem = poll;
      this.listExport = true;
    },
    resetPoll(poll) {
      this.selectedItem = duplicateVar(poll);
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Reset result'),
        text: `${this.$t('Resetting poll result cannot be undone, proceed')}?`,
        accept: () => this.resetRecord(poll),
        acceptText: this.$t('Reset'),
      });
    },

    resetRecord(poll) {
      this.$vs.loading();
      const callback = () => {
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Voting'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      votingsApi.reset(poll && poll.id ? poll.id : null, callback, errorCallback);
    },

    deletePoll(poll) {
      this.selectedItem = duplicateVar(poll);
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm Delete'),
        text: `${this.$t('Delete poll')} "${poll && poll.name ? poll.name : ''}"?`,
        accept: () => this.deleteRecord(poll),
        acceptText: this.$t('Delete'),
      });
    },

    deleteRecord(poll) {
      const callback = () => {
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Voting'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      votingsApi.delete(poll && poll.id ? poll.id : null, callback, errorCallback);
    },

    exportPolls(type) {
      this.isExporting = true;
      const callback = async (response) => {
        const url = await fetch(response.data);
        const blob = await url.blob();
        const link = document.createElement('a');
        const fileName = `${this.$t('Q & A')} - ${this.eventData.name}.${type === 'excel' ? 'xlsx' : 'doc'}`;
        const blobUrl = window.URL.createObjectURL(blob);
        link.href = blobUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
        this.listExport = false;
        this.isExporting = false;
      };
      const errorCallback = () => {
        this.listExport = false;
        this.isExporting = false;
      };
      votingsApi.exportList(type, this.selectedItem.id, callback, errorCallback);
    },
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>

<style lang="scss">
.item-poll {
  width: 100%;
  min-height: 180px;
  background-color: white;
  padding: 28px;
  border: 1px solid #F8F8F8;
  &:hover {
    background-color: #F8F8F8;
  }
}
.moderation-menu {
  width: 120px;
}
</style>

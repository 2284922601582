<template>
	<div>
    <div class="pt-2">
		<div class="flex justify-between items-center mb-6">
			<div class="font-bold text-lg">{{ $t('Polls') }}</div>
			<div class="flex items-center">
        <vs-dropdown class="dd-actions cursor-pointer" vs-trigger-click>
              <div>
                <vs-button class="ml-2 btn-icon px-4 p-1" color="secondary" type="border">
                  <div class="flex items-center">
                    <div>
                      <svg class="w-8 mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>chart-box-outline</title><path d="M9 17H7V10H9V17M13 17H11V7H13V17M17 17H15V13H17V17M19 19H5V5H19V19.1M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z" /></svg>
                    </div>
                    <div>{{ $t('Create Polls') }}</div>
                  </div>
                </vs-button>
              </div>
              <vs-dropdown-menu>
                <!-- MultipleChoice -->
                <vs-dropdown-item>
                  <div @click="createPoll('multiplechoice')" class="flex items-center polls-create-menu">
                    <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>playlist-check</title><path d="M14 10H3V12H14V10M14 6H3V8H14V6M3 16H10V14H3V16M21.5 11.5L23 13L16 20L11.5 15.5L13 14L16 17L21.5 11.5Z" /></svg></div>
                    <div class="ml-2">{{ $t('Multiple Choice') }}</div>
                  </div>
                </vs-dropdown-item>
                <!-- Open text -->
                <vs-dropdown-item>
                  <div @click="createPoll('opentext')" class="flex items-center polls-create-menu">
                    <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>comment-check-outline</title><path d="M9 22C8.45 22 8 21.55 8 21V18H4C2.9 18 2 17.11 2 16V4C2 2.89 2.9 2 4 2H20C21.11 2 22 2.9 22 4V16C22 17.11 21.11 18 20 18H13.9L10.2 21.71C10 21.9 9.75 22 9.5 22H9M10 16V19.08L13.08 16H20V4H4V16H10M15.6 6L17 7.41L10.47 14L7 10.5L8.4 9.09L10.47 11.17L15.6 6" /></svg></div>
                    <div class="ml-2">{{ $t('Open Text') }}</div>
                  </div>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
			</div>
		</div>
    <ItemPollsActive
      v-if="livePoll"
      :poll="livePoll"
      :eventData="eventData"
      @editMultipleChoice="editMultipleChoice"
      @closeMultipleChoice="closeMultipleChoice"
      @togglePublish="togglePublish"
      @editOpenText="editOpenText"
      @closeOpenText="closeOpenText"
    >
    </ItemPollsActive>
    <ItemPolls
      :polls="polls"
      :eventData="eventData"
      @editMultipleChoice="editMultipleChoice"
      @closeMultipleChoice="closeMultipleChoice"
      @togglePublish="togglePublish"
      @editOpenText="editOpenText"
      @closeOpenText="closeOpenText"
    >
    </ItemPolls>
    <div v-if="polls && polls.length === 0" class="item-poll-live-empty shadow relative">
      {{ $t('No polling activated') }}
    </div>
    <div class="text-center text-primary font-bold text-xl mt-2">
      <vs-button v-if="!isFetching && totalPage !== page" @click="loadMore()" class="ml-2 btn-icon px-6 py-4">
        {{ $t('Load More') }}
      </vs-button>
    </div>
    <MultipleChoicePolls @togglePublish="togglePublish" :isShowMultipleChoice="isShowMultipleChoice" @closeMultipleChoice="closeMultipleChoice" :eventId="eventData.id" :selectedItem="selectedItem"></MultipleChoicePolls>
    <OpenTextPolls @togglePublish="togglePublish" :isShowOpenText="isShowOpenText" @closeOpenText="closeOpenText" :eventId="eventData.id" :selectedItem="selectedItem"></OpenTextPolls>
    </div>
  </div>
</template>

<script>
import votingsApi from '@/api/voting';
import { getAxiosErrorMessage, duplicateVar } from '@/lib/helper';
import ItemPolls from '@/components/polls/ItemPolls.vue';
import ItemPollsActive from '@/components/polls/ItemPollsActive.vue';
import MultipleChoicePolls from '@/components/polls/MultipleChoicePolls.vue';
import OpenTextPolls from '@/components/polls/OpenTextPolls.vue';


export default {
  components: {
    ItemPolls,
    MultipleChoicePolls,
    OpenTextPolls,
    ItemPollsActive,
  },
  props: {
    eventData: {
      type: Object,
      default: () => {},
    },
  },
  sockets: {
    votings_add(voting) {
      this.updateVoting(voting);
    },
    votings_update(voting) {
      this.updateVoting(voting);
    },
    votings_delete(voting) {
      this.deleteVoting(voting);
    },
    votings_duplicate(voting) {
      this.updateVoting(voting);
    },
    votings_active(voting) {
      this.updatePublish(voting);
      this.fetchActive();
    },
    votings_stop(voting) {
      this.updatePublish(voting);
      this.fetchActive();
    },
    votings_results(voting) {
      this.updateVoting(voting);
    },
  },
  data() {
    return {
      page: 1,
      totalPage: null,
      polls: [],
      isShowMultipleChoice: false,
      isShowOpenText: false,
      selectedItem: null,
      isFetching: false,
      livePoll: null,
    };
  },
  computed: {
  },
  methods: {
    resetAll() {
      this.polls = [];
      this.livePoll = null;
    },
    loadMore() {
      this.page = this.page + 1;
      this.fetchList(false);
    },
    fetchActive() {
      this.livePoll = null;
      this.$vs.loading();
      const callback = (response) => {
        this.livePoll = response.data;
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        if (message !== 'No active vote' && message !== 'Ingen aktiv avstemning') {
          this.$vs.notify({
            title: this.$t('Voting'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
        }
        this.livePoll = null;
      };
      votingsApi.getActive(this.eventData && this.eventData.id ? this.eventData.id : null, callback, errorCallback);
    },
    fetchList(reset = true) {
      if (reset) this.polls = [];
      this.$vs.loading();
      this.isFetching = true;
      const params = {
        page: this.page,
        limit: 25,
        order_by: 'created_at',
        sort_by: 'asc',
        event_slug: this.eventData && this.eventData.slug ? this.eventData.slug : null,
      };
      const callback = (response) => {
        const polls = response.data;
        for (let index = 0; index < polls.length; index++) {
          const element = polls[index];
          this.updateVoting(element);
          this.fetchActive();
        }
        this.totalPage = response.last_page;
        this.$vs.loading.close();
        this.isFetching = false;
      };
      const errorCallback = (e) => {
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Voting'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isFetching = false;
      };
      votingsApi.getList(this.eventData && this.eventData.id ? this.eventData.id : null, params, callback, errorCallback);
    },
    editMultipleChoice(poll) {
      this.selectedItem = duplicateVar(poll);
      if (poll.type === 'multiple_choice') {
        this.isShowMultipleChoice = true;
      } else {
        this.isShowOpenText = true;
      }
    },
    editOpenText(poll) {
      this.selectedItem = duplicateVar(poll);
      this.isShowOpenText = true;
    },
    updateVoting(item) {
      const index = this.polls.findIndex(({ id }) => id === item.id);
      if (index === -1) {
        this.polls.push(item);
      } else {
        this.$set(this.polls, index, item);
        if (item && item.is_published) {
          this.livePoll = item;
        }
      }
    },
    updatePublish(item) {
      const index = this.polls.findIndex(({ id }) => id === item.id);
      if (index === -1) {
        this.polls.push(item);
      } else {
        this.$set(this.polls, index, item);
      }
      for (let indexPoll = 0; indexPoll < this.polls.length; indexPoll++) {
        const element = this.polls[indexPoll];
        if (item.id !== element.id) {
          element.is_published = 0;
        }
      }
    },
    deleteVoting(item) {
      const index = this.polls.findIndex(({ id }) => id === item.id);
      this.polls.splice(index, 1);
    },
    createPoll(type) {
      if (type === 'multiplechoice') {
        this.isShowMultipleChoice = true;
        this.isShowOpenText = false;
      } else {
        this.isShowMultipleChoice = false;
        this.isShowOpenText = true;
      }
    },
    togglePublish(poll) {
      this.$vs.loading();
      const callback = (response) => {
        const message = response.message;
        this.$vs.loading.close();
        this.$vs.notify({
          title: this.$t('Voting'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
      };
      const errorCallback = (e) => {
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Voting'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      votingsApi.publish(poll && poll.id ? poll.id : null, callback, errorCallback);
    },
    closeMultipleChoice() {
      this.selectedItem = null;
      this.isShowMultipleChoice = false;
    },
    closeOpenText() {
      this.selectedItem = null;
      this.isShowOpenText = false;
    },
  },
  mounted() {
    this.fetchList();
  },
  created() {
  },
  destroyed() {
  },
};
</script>
<style>
  .polls-create-menu{
    width: 200px;
  }
</style>

<template>
	<div>
		<vs-prompt
      @close="$emit('closeMultipleChoice')"
			class="multiple-choice-poll"
      title="Multiple Choice Poll"
      :disabled-outside="true"
      :buttons-hidden="true"
      :active.sync="isModalActiveLocal">
      <!-- question section -->
      <div class="block lg:flex gap-4">
        <div class="w-full lg:w-1/2 relative">
          <label class="text-secondary">Question</label>
          <vs-textarea
              class="w-full mt-2"
              name="item-content"
              rows="4"
              v-model="dataName"
          />
        </div>
        <div class="w-full lg:w-1/2 relative">
          <div class="lg:px-12">
            <label class="text-secondary">Settings</label>
            <div class="mt-2 flex justify-between items-center">
                <div class="font-bold text-lg">
                    <div>{{ $t('Multiple Options') }}</div>
                    <div class="font-normal text-small text-secondary">{{ $t('Allow participant to choose multiple answer') }}.</div>
                </div>
                <div class=" ml-6"><vs-switch @click.stop="" color="success" v-model="dataIsMultipleAnswer"/></div>
            </div>
            <div class="mt-4 flex justify-between items-center">
                <div class="font-bold text-lg">
                    <div>{{ $t('Hide live result') }}</div>
                    <div class="font-normal text-small text-secondary">{{ $t('Do not show result result after vote') }}.</div>
                </div>
                <div class=" ml-6"><vs-switch @click.stop="" color="success" v-model="dataIsHideLive"/></div>
            </div>
          </div>
        </div>
      </div>
      <!-- answer section -->
      <div>
        <div class="flex justify-between w-1/2">
          <div class="text-secondary">{{ $t('Answer') }}</div>
          <div class="pr-2 text-secondary text-small">{{ $t('Type and press enter to save, and repeat') }}</div>
        </div>
        <div>
          <draggable
            :list="dataOptions"
            style="max-height: 37vh;"
            class="overflow-y-auto w-full lg:w-1/2 pr-4"
            v-bind="dragOptions"
            handle=".dragAnswerSelector"
            @change="onAnswerChange">
            <transition-group>
              <div
                v-for="(answer, index) in dataOptions"
                :key="`mc_${index}`">
                <div class="flex items-center mt-6">
                  <div class="pointer mr-2 dragAnswerSelector">
                    <svg class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>menu</title><path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" /></svg>
                  </div>
                  <div class="mr-2">
                    <svg class="w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check-circle-outline</title><path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" /></svg>
                  </div>
                  <vs-input v-filter-input
                    class="w-full answer-input pointer-events-none"
                    placeholder="Add option"
                    :value="answer"
                    @keyup.enter="addAnswer"
                    ></vs-input>
                  <div @click="deleteAnswer(index)" class="mr-2 pointer">
                    <svg class="w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>close-circle-outline</title><path d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z" /></svg>
                  </div>
                </div>
              </div>
            </transition-group>
          </draggable>
          <div class="w-full lg:w-1/2 mb-12" :class="{'pr-12': dataOptions && dataOptions.length > 0}">
            <vs-input v-filter-input
              class="w-full mt-6 answer-input pr-2"
              :class="{'ml-10 pl-8 pr-12': dataOptions && dataOptions.length > 0}"
              placeholder="Add option"
              v-model="dataAddAnswer"
              @keyup.enter="addAnswer()"
              ></vs-input>
          </div>
        </div>
      </div>
      <!-- button section -->
      <div class="absolute bottom-0 right-0 mr-10 flex justify-end mb-6">
        <vs-button
            v-if="dataIdPoll"
            class="px-6 py-2 mr-4 pl-12"
            color="green"
            @click="togglePublish(selectedItem)">
            <div class="flex items-center relative">
                <svg class="w-6 absolute left-0" style="margin-left: -24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>play</title><path fill="currentColor" d="M8,5.14V19.14L19,12.14L8,5.14Z" /></svg>
                {{ $t('Launch') }}
            </div>
        </vs-button>
        <vs-button
          :disabled="!isFormValid"
          class="px-8 py-2"
          @click="submitData()">
          <div class="flex items-center">
              {{ $t('Save') }}
          </div>
        </vs-button>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import votingsApi from '@/api/voting';
import {
  getAxiosErrorMessage,
} from '@/lib/helper';

export default {
  components: {
    draggable,
  },
  props: {
    isShowMultipleChoice: {
      type: Boolean,
      default: () => false,
    },
    selectedItem: {
      type: Object,
      default: () => null,
    },
    eventId: {
      type: Number,
      default: () => 0,
    },
  },
  watch: {
    isShowMultipleChoice() {
      if (this.isShowMultipleChoice) {
        this.isModalActiveLocal = true;
        if (this.selectedItem) {
          this.dataIdPoll = this.selectedItem.id;
          this.dataName = this.selectedItem.name;
          this.dataIsHideLive = this.selectedItem.is_hide_live_result;
          this.dataIsMultipleAnswer = this.selectedItem.is_multiple_options;
          if (this.selectedItem.options && this.selectedItem.options.length > 0) {
            const mapAnswer = this.selectedItem.options.map(answer => answer.name);
            this.dataOptions = mapAnswer;
          }
          this.dataType = 'multiple_choice';
        }
      } else {
        this.isModalActiveLocal = false;
        this.dataIdPoll = null;
        this.dataName = '';
        this.dataIsHideLive = false;
        this.dataIsMultipleAnswer = false;
        this.dataOptions = [];
        this.dataType = 'multiple_choice';
      }
    },
    isModalActiveLocal() {
      if (!this.isModalActiveLocal) this.$emit('closeMultipleChoice');
    },
  },
  data() {
    return {
      isModalActiveLocal: false,
      dataIdPoll: null,
      dataName: '',
      dataIsHideLive: false,
      dataIsMultipleAnswer: false,
      dataOptions: [],
      dataAddAnswer: '',
      dataType: 'multiple_choice',
    };
  },
  computed: {
    isFormValid() {
      return this.dataName !== ''
      && this.dataOptions && this.dataOptions.length > 1;
    },
    dragOptions() {
      return {
        animation: 100,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
  methods: {
    addAnswer() {
      if (this.dataAddAnswer.trim() !== '') {
        this.dataOptions.push(this.dataAddAnswer.trim());
        this.dataAddAnswer = ''; // Clear the input field after adding the answer
      }
    },
    deleteAnswer(index) {
      this.dataOptions.splice(index, 1);
    },
    submitData() {
      this.$vs.loading();
      const params = {
        name: this.dataName,
        is_hide_live_result: this.dataIsHideLive,
        is_multiple_options: this.dataIsMultipleAnswer,
        options: this.dataOptions,
        type: this.dataType,
      };
      const callback = (response) => {
        this.$emit('updateVoting', response.data);
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Poll'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      if (!this.dataIdPoll) votingsApi.create(this.eventId ? this.eventId : null, params, callback, errorCallback);
      if (this.dataIdPoll) votingsApi.update(this.dataIdPoll ? this.dataIdPoll : null, params, callback, errorCallback);
    },
    onAnswerChange() {
    },
    togglePublish(poll) {
      this.$emit('togglePublish', poll);
      this.isModalActiveLocal = false;
    },
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
<style lang="scss">
	.multiple-choice-poll.con-vs-dialog .vs-dialog {
    min-width: 400px!important;
    min-height: 600px!important;
    position: relative;
      @media (min-width: 1000px) {
          min-width: 1000px!important;
      }
  }

  .answer-input {
    .vs-input--input {
      border: none!important; /* Remove the default border */
      border-bottom: 1px solid #ccc!important; /* Add a bottom border */
      border-radius: 0; /* Remove border radius if needed */
      padding: 5px; /* Adjust padding as needed */
      background-color: transparent; /* Make the background transparent */
      width: 100%; /* Optional: Set the width to 100% */

      &:focus {
        border: none!important; /* Remove the default border */
        border-bottom: 1px solid #ccc!important; /* Add a bottom border */
        border-radius: 0; /* Remove border radius if needed */
        padding: 5px; /* Adjust padding as needed */
        background-color: transparent; /* Make the background transparent */
        width: 100%; /* Optional: Set the width to 100% */
      }
    }
  }

</style>

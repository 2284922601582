<template>
	<div>
    <div v-if="livePoll" class="item-poll-live mt-1 shadow relative">
      <div class="pt-6 px-6">
        <!-- content -->
        <div class="flex flex-col justify-between h-full item-poll-content">
          <!-- Name -->
          <div class="text-bold text-xl poll-name">{{ livePoll && livePoll.name ? livePoll.name : 'Voting Description' }}</div>

          <!-- Total Count -->
          <div v-if="eventPollShowVoter || activeRouteName === 'EventPolls'" class="flex mt-8 mb-4 poll-counter">
            <div v-tooltip="$t('People Voted')" class="flex items-center mr-6">
              <svg class="w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#BFBFBF" d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" /></svg>
              <div class="font-bold">{{ livePoll && livePoll.meta && livePoll.meta.total_voter ? livePoll.meta.total_voter : 0 }}</div>
            </div>
            <div v-tooltip="$t('Number Voted')" class="flex items-center">
              <svg class="w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#BFBFBF" d="M18,13L21,16V20C21,21.11 20.1,22 19,22H5C3.89,22 3,21.1 3,20V16L6,13H6.83L8.83,15H6.78L5,17H19L17.23,15H15.32L17.32,13H18M19,20V19H5V20H19M11.34,15L6.39,10.07C6,9.68 6,9.05 6.39,8.66L12.76,2.29C13.15,1.9 13.78,1.9 14.16,2.3L19.11,7.25C19.5,7.64 19.5,8.27 19.11,8.66L12.75,15C12.36,15.41 11.73,15.41 11.34,15M13.46,4.41L8.5,9.36L12.05,12.9L17,7.95L13.46,4.41Z" /></svg>
              <div class="font-bold">{{ livePoll && livePoll.meta && livePoll.meta.total_votes ? livePoll.meta.total_votes : 0 }}</div>
            </div>
          </div>
        </div>
        <div class="absolute left-0" style="height: 1px; background-color: #e5e5e5; width: 100%"></div>
        <!-- is locked information -->
        <div v-if="livePoll && livePoll.is_locked && isShowLockInformation">
          <div class="absolute left-0 w-full px-10 flex justify-between items-center py-8 px-0" :style="customStyleLock">
            <div class="flex items-center font-medium">
              <div><svg class="w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>lock</title><path d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" /></svg></div>
              <div>{{ $t('Voting Locked') }}</div>
            </div>
            <div>
              <vs-button
                class="px-8 py-2"
                @click="lockVote(livePoll)">
                <div class="flex items-center">
                    {{ $t('Unlock Voting') }}
                </div>
              </vs-button>
            </div>
          </div>
        </div>
        <!-- is view information -->
        <div v-if="livePoll && livePoll.is_hide_live_result">
          <div class="absolute left-0 w-full px-10 flex justify-between items-center py-8 px-0" :style="customStyleView">
            <div class="flex items-center font-medium">
              <div><svg class="w-6 mr-4 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>eye-off</title><path d="M11.83,9L15,12.16C15,12.11 15,12.05 15,12A3,3 0 0,0 12,9C11.94,9 11.89,9 11.83,9M7.53,9.8L9.08,11.35C9.03,11.56 9,11.77 9,12A3,3 0 0,0 12,15C12.22,15 12.44,14.97 12.65,14.92L14.2,16.47C13.53,16.8 12.79,17 12,17A5,5 0 0,1 7,12C7,11.21 7.2,10.47 7.53,9.8M2,4.27L4.28,6.55L4.73,7C3.08,8.3 1.78,10 1,12C2.73,16.39 7,19.5 12,19.5C13.55,19.5 15.03,19.2 16.38,18.66L16.81,19.08L19.73,22L21,20.73L3.27,3M12,7A5,5 0 0,1 17,12C17,12.64 16.87,13.26 16.64,13.82L19.57,16.75C21.07,15.5 22.27,13.86 23,12C21.27,7.61 17,4.5 12,4.5C10.6,4.5 9.26,4.75 8,5.2L10.17,7.35C10.74,7.13 11.35,7 12,7Z" /></svg></div>
              <div>{{ $t('Participant can not see the results') }}</div>
            </div>
            <div>
              <vs-button
                class="px-8 py-2"
                @click="toggleView(livePoll)">
                <div class="flex items-center">
                    {{ $t('Show results') }}
                </div>
              </vs-button>
            </div>
          </div>
        </div>
      </div>
      <!-- result multiple -->
      <div class="font-bold text-xl pl-4" v-if="livePoll && livePoll.options && livePoll.options.length > 0" :style="customStyleResult">
        <div class="pr-8" v-for="(option, index) in filteredPolls" :key="`${option.id}_${index}`">
          <div class="flex">
            <div class="poll-option-name pr-1">{{ index + 1 }}.</div>
            <div class="poll-option-name">{{ option.name }}</div>
          </div>
          <div class="flex items-center pl-5">
            <div class="progress-bar my-2 mb-4">
              <div class="progress" :style="{ width: getPollPercentage(livePoll.results, option.id, livePoll && livePoll.meta && livePoll.meta.total_votes ? livePoll.meta.total_votes : 0) + '%' }"></div>
            </div>
            <div class="percentage-number">
              {{ getPollPercentage(livePoll.results, option.id, livePoll && livePoll.meta && livePoll.meta.total_votes ? livePoll.meta.total_votes : 0) }}{{ !eventPollCounter ? '%' : ''}}
            </div>
          </div>
        </div>
      </div>
      <!-- result open text -->
      <div class="text-xl overflow-y-auto pl-8" style="max-height: 34vh;" v-if="livePoll && livePoll.type === 'open_text' && livePoll.results && livePoll.results.length > 0" :style="customStyleResult">
        <div v-for="(result, index) in livePoll.results" :key="`${result.id}_${index}`">
          <div class="font-bold">{{ result.sender_name }}</div>
          <div class="flex items-center">
            <div class="text-base mt-1 mb-4">
              {{ result.answer }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="item-poll-live-empty shadow relative">
      {{ $t('No polling activated') }}
    </div>
  </div>
</template>

<script>
import votingsApi from '@/api/voting';
import { getAxiosErrorMessage } from '@/lib/helper';

export default {
  components: {
  },
  props: {
    livePoll: {
      type: Object,
      default: () => {},
    },
    eventData: {
      type: Object,
      default: () => {},
    },
    isShowLockInformation: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    filteredPolls() {
      if (this.livePoll && this.livePoll.options && this.livePoll.options.length > 0) {
        const optionsCopy = [...this.livePoll.options];
        const final = optionsCopy.sort((a, b) => b.meta.total_votes - a.meta.total_votes);
        return final;
      }
      return [];
    },
    customStyleResult() {
      if (this.livePoll && this.livePoll.is_hide_live_result === 1 && this.livePoll.is_locked === 1) {
        return 'margin-top: 12em';
      }
      if (this.isShowLockInformation && ((this.livePoll && this.livePoll.is_hide_live_result === 1) || (this.livePoll && this.livePoll.is_locked === 1))) {
        return 'margin-top: 7em;';
      }
      return 'margin-top: 2em';
    },
    customStyleView() {
      if (this.livePoll && this.livePoll.is_hide_live_result === 1 && this.livePoll.is_locked === 1) {
        return 'background-color: #F7f7f6; margin-top: 89px; border-bottom: 1px solid #e5e5e5;';
      }
      if (this.livePoll && this.livePoll.is_hide_live_result === 1) {
        return 'background-color: #F7f7f6; margin-top: 1px; border-bottom: 1px solid #e5e5e5;';
      }
      return '';
    },
    customStyleLock() {
      return 'background-color: #F7f7f6; margin-top: 1px; border-bottom: 1px solid #e5e5e5;';
    },
    eventPollCounter() {
      const eventData = this.eventData && this.eventData.settings ? this.eventData.settings : null;
      let settings = null;
      let settingsPoll = null;
      if (eventData) settings = JSON.parse(eventData);
      if (settings) settingsPoll = settings && settings.polls && settings.polls.is_show_result ? settings.polls.is_show_result : false;
      return settingsPoll;
    },
    eventPollShowVoter() {
      const eventData = this.eventData && this.eventData.settings ? this.eventData.settings : null;
      let settings = null;
      let settingsPoll = null;
      if (eventData) settings = JSON.parse(eventData);
      if (settings) settingsPoll = settings && settings.polls && settings.polls.is_vote_counter_visible ? settings.polls.is_vote_counter_visible : false;
      return settingsPoll;
    },
    activeRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    getPollPercentage(results, pollId, total) {
      if (results && results.length > 0) {
        const filteredOptions = results.filter(option => option.voting_option_id === pollId);
        const totalVotes = total;
        const currentVote = filteredOptions.length;
        const percentage = (currentVote / totalVotes) * 100;
        if (percentage && !this.eventPollCounter) {
          return this.formatPercentage(percentage);
        } if (percentage && this.eventPollCounter) {
          return currentVote;
        }
        return 0;
      }
      return 0;
    },
    formatPercentage(percentage) {
      if (percentage % 1 !== 0) {
        return percentage.toFixed(1); // If there's a decimal, fix it to 2 decimal places
      }
      return Math.round(percentage); // If it's an integer, round it to the nearest whole number
    },
    lockVote(poll) {
      this.$vs.loading();
      const params = {
        is_locked: !poll.is_locked,
      };
      const callback = (response) => {
        this.$emit('updateVoting', response.data);
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Poll'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      votingsApi.update(poll.id ? poll.id : null, params, callback, errorCallback);
    },
    toggleView(poll) {
      this.$vs.loading();
      const params = {
        is_hide_live_result: !poll.is_hide_live_result,
      };
      const callback = (response) => {
        this.$emit('updateVoting', response.data);
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Poll'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      votingsApi.update(poll.id ? poll.id : null, params, callback, errorCallback);
    },
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
<style>
  .item-poll-live {
    width: 100%;
    min-height: 180px;
    background-color: white;
    border: 1px solid #F8F8F8;
  }
  .item-poll-live-empty {
    width: 100%;
    min-height: 180px;
    background-color: white;
    border: 1px solid #F8F8F8;
    padding: 28px;
  }
</style>

<template>
  <div>
    <vs-prompt
      title="View Poll Result"
      class="poll-result"
      :disabled-outside="true"
      :buttons-hidden="true"
      :active.sync="isModalActiveLocal">
      <div class="p-4">
        <!-- content -->
        <div class="flex flex-col justify-between h-full mb-12">
          <div class="text-bold text-xl">{{ poll && poll.name ? poll.name : 'Voting Description' }}</div>
        </div>
        <!-- result multiple choice -->
        <div style="max-height: 40vh" class="mt-4 font-bold text-xl overflow-y-auto" v-if="poll && poll.options && poll.options.length > 0">
          <div class="pr-8" v-for="(option, index) in filteredPolls" :key="`${option.id}_${index}`">
            <div class="flex">
              <div class="poll-option-name pr-1">{{ index + 1 }}.</div>
              <div class="poll-option-name">{{ option.name }}</div>
            </div>
            <div class="flex items-center pl-5">
              <div class="progress-bar my-2 mb-4">
                <div class="progress" :style="{ width: getPollPercentage(poll.results, option.id, poll && poll.meta && poll.meta.total_votes ? poll.meta.total_votes : 0) + '%' }"></div>
              </div>
              <div class="percentage-number">
                {{ getPollPercentage(poll.results, option.id, poll && poll.meta && poll.meta.total_votes ? poll.meta.total_votes : 0) }}{{ !eventPollCounter ? '%' : ''}}
              </div>
            </div>
          </div>
        </div>
        <!-- result open text -->
        <div class="text-xl overflow-y-auto" style="max-height: 34vh;" v-if="poll && poll.type === 'open_text' && poll.results && poll.results.length > 0" :style="customStyleResult">
          <div v-for="(result, index) in poll.results" :key="result.id">
            <div class="font-bold ">{{ index + 1 }}. {{ result.sender_name }}</div>
            <div class="flex items-center">
              <div class="text-base ml-5 my-2 mb-4">
                {{ result.answer }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-between mt-6 px-4">
        <div class="flex flex-col justify-between h-full ">
            <div class="flex ">
              <div class="flex items-center mr-6">
                <svg class="w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>account</title><path fill="#BFBFBF" d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" /></svg>
                <div class="font-bold">{{ poll && poll.meta && poll.meta.total_voter ? poll.meta.total_voter : 0 }}</div>
              </div>
              <div class="flex items-center">
                <svg class="w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>vote-outline</title><path fill="#BFBFBF" d="M18,13L21,16V20C21,21.11 20.1,22 19,22H5C3.89,22 3,21.1 3,20V16L6,13H6.83L8.83,15H6.78L5,17H19L17.23,15H15.32L17.32,13H18M19,20V19H5V20H19M11.34,15L6.39,10.07C6,9.68 6,9.05 6.39,8.66L12.76,2.29C13.15,1.9 13.78,1.9 14.16,2.3L19.11,7.25C19.5,7.64 19.5,8.27 19.11,8.66L12.75,15C12.36,15.41 11.73,15.41 11.34,15M13.46,4.41L8.5,9.36L12.05,12.9L17,7.95L13.46,4.41Z" /></svg>
                <div class="font-bold">{{ poll && poll.meta && poll.meta.total_votes ? poll.meta.total_votes : 0 }}</div>
              </div>
            </div>
        </div>
        <div>
          <vs-button
              class="px-8 m-0 mt-2 mr-4"
              color="#f3f3f3"
              text-color="black"
              @click="isModalActiveLocal = false">
              <div class="flex items-center">
                  {{ $t('Close') }}
              </div>
          </vs-button>
          <vs-button
              class="px-8 m-0 mt-2"
              @click="editPoll(poll)">
              <div class="flex items-center">
                  {{ $t('Edit') }}
              </div>
          </vs-button>
        </div>
    </div>
    </vs-prompt>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    isShowModalResultsPoll: {
      type: Boolean,
      default: () => false,
    },
    poll: {
      type: Object,
      default: () => {},
    },
    eventData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isModalActiveLocal: false,
    };
  },
  watch: {
    isShowModalResultsPoll() {
      if (this.isShowModalResultsPoll) {
        this.isModalActiveLocal = true;
      } else {
        this.isModalActiveLocal = false;
      }
    },
    isModalActiveLocal() {
      if (!this.isModalActiveLocal) this.$emit('closeModalResultsPoll');
    },
  },
  computed: {
    filteredPolls() {
      if (this.poll && this.poll.options && this.poll.options.length > 0) {
        const optionsCopy = [...this.poll.options];
        const final = optionsCopy.sort((a, b) => b.meta.total_votes - a.meta.total_votes);
        return final;
      }
      return [];
    },
    eventPollCounter() {
      const eventData = this.eventData && this.eventData.settings ? this.eventData.settings : null;
      let settings = null;
      let settingsPoll = null;
      if (eventData) settings = JSON.parse(eventData);
      if (settings) settingsPoll = settings && settings.polls && settings.polls.is_show_result ? settings.polls.is_show_result : false;
      return settingsPoll;
    },
  },
  methods: {
    getPollPercentage(results, pollId, total) {
      if (results && results.length > 0) {
        const filteredOptions = results.filter(option => option.voting_option_id === pollId);
        const totalVotes = total;
        const currentVote = filteredOptions.length;
        const percentage = (currentVote / totalVotes) * 100;
        if (percentage && !this.eventPollCounter) {
          return this.formatPercentage(percentage);
        } if (percentage && this.eventPollCounter) {
          return currentVote;
        }
        return 0;
      }
      return 0;
    },
    formatPercentage(percentage) {
      if (percentage % 1 !== 0) {
        return percentage.toFixed(1); // If there's a decimal, fix it to 2 decimal places
      }
      return Math.round(percentage); // If it's an integer, round it to the nearest whole number
    },
    editPoll(poll) {
      this.$emit('editPoll', poll);
      this.isModalActiveLocal = false;
    },
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
<style>
.poll-result.con-vs-dialog .vs-dialog {
  max-height: 700px!important;
  min-width: 700px!important;
}
</style>

<template>
	<div>
		<vs-prompt
        title="Share"
        :disabled-outside="true"
        :buttons-hidden="true"
        :active.sync="isModalActiveLocal">
        <!-- reply section -->
        <div class="mb-5 relative">
          <label class="text-normal text-secondary">
            {{ $t('Direct Link') }}
          </label>
          <div class="flex items-center">
            <vs-input id="name-poll-share truncate" class="w-full mt-1" name="item-name"
              v-model="eventData.link_code" readonly />
            <div class="icon-copy-poll pointer pt-1 ml-4">
              <feather-icon icon="CopyIcon" :svgClasses="'w-5 h-5 mr-2'"
                v-clipboard:copy="eventData.link_code" v-clipboard:success="onCopy"
                v-clipboard:error="onError" />
            </div>
          </div>
        </div>
        <div class="mb-5 relative">
          <label class="text-normal text-secondary">
            {{ $t('Embed Code') }}
          </label>
          <div class="flex items-start">
            <vs-textarea  rows="5" id="name-poll-embed truncate" class="w-full mt-1" name="item-name"
              v-model="embedCode" readonly />
            <div class="icon-copy-poll pointer mt-2 ml-4">
              <feather-icon icon="CopyIcon" :svgClasses="'w-5 h-5 mr-2'"
                v-clipboard:copy="embedCode" v-clipboard:success="onCopy"
                v-clipboard:error="onError" />
            </div>
          </div>
        </div>
        <div class="button-section">
          <vs-button
              class="px-6 m-0 mt-3"
              color="#f3f3f3"
              text-color="black"
              @click="isModalActiveLocal = false">
              <div class="flex items-center">
                  {{ $t('Close') }}
              </div>
          </vs-button>
        </div>
    </vs-prompt>
    </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    isShowModalSharePoll: {
      type: Boolean,
      default: () => false,
    },
    selectedItem: {
      type: Object,
      default: () => null,
    },
    eventData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    isShowModalSharePoll() {
      if (this.isShowModalSharePoll) {
        this.isModalActiveLocal = true;
      } else {
        this.isModalActiveLocal = false;
      }
    },
    isModalActiveLocal() {
      if (!this.isModalActiveLocal) this.$emit('closeModalSharePoll');
    },
  },
  data() {
    return {
      directLink: '',
      isModalActiveLocal: false,
    };
  },
  computed: {
    embedCode() {
      return `<iframe width="1920" height="1080" src="${this.eventData.link_code}" title="Koblatil" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
    },
  },
  methods: {
    onCopy() {
      this.$vs.notify({
        text: 'Copied to clipboard',
        iconPack: 'feather',
        position: 'bottom-right',
        icon: 'icon-check-circle',
      });
    },
    onError() {
      this.$vs.notify({
        title: 'Failed',
        text: 'Error in copying',
        color: 'danger',
        iconPack: 'feather',
        position: 'bottom-right',
        icon: 'icon-alert-circle',
      });
    },
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
<style lang="scss">
</style>

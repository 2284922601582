<template>
  <div>
    <!-- warning box -->
    <div style="height: 60px; background-color: #F8F8F8; z-index: 999" class="fixed w-full top-0 left-0"></div>
    <div v-if="!isPollEnabled && !isFetching" class="disabled-poll-announcement flex">
      <svg class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>alert-circle-outline</title><path fill="currentColor" d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" /></svg>
      <div class="ml-2">{{ $t('The Polls page has been disabled, enable this in Settings') }}</div>
    </div>

    <div class="xl:mt-12 xl:pt-1 lg:flex lg:gap-8">
      <!-- topbar -->
      <div v-if="!isFetching" class="flex top-0 right-0 top-bar-qna">
        <vs-dropdown style="z-index: 9999" class="dd-actions cursor-pointer" vs-trigger-click>
        <div>
          <vs-button class="ml-2 btn-icon px-3 py-2 pointer" color="secondary" type="border">
            <div class="flex items-center text-bold">
              {{ $t('Share') }}
              <svg class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <title>chevron-down</title>
                <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
              </svg>
            </div>
          </vs-button>
        </div>
        <vs-dropdown-menu>
          <!-- Share -->
          <vs-dropdown-item @click="shareLinkQuestion()">
            <div class="flex items-center share-menu">
              <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <title>link</title>
                  <path
                    d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z" />
                </svg></div>
              <div class="ml-2">{{ $t('Question page') }}</div>
            </div>
          </vs-dropdown-item>
          <!-- share -->
          <vs-dropdown-item @click="shareLinkVideo()">
            <div class="flex items-center share-menu">
              <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <title>link</title>
                  <path
                    d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z" />
                </svg></div>
              <div class="ml-2">{{ $t('Event page URL (with video)') }}</div>
            </div>
          </vs-dropdown-item>
          <!-- Download -->
          <vs-dropdown-item @click="downloadCanvasQR()">
            <div class="flex items-center share-menu">
              <div><svg class="w-6 pt-1 mr-2" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24">
                  <title>qrcode</title>
                  <path
                    d="M3,11H5V13H3V11M11,5H13V9H11V5M9,11H13V15H11V13H9V11M15,11H17V13H19V11H21V13H19V15H21V19H19V21H17V19H13V21H11V17H15V15H17V13H15V11M19,19V15H17V19H19M15,3H21V9H15V3M17,5V7H19V5H17M3,3H9V9H3V3M5,5V7H7V5H5M3,15H9V21H3V15M5,17V19H7V17H5Z" />
                </svg></div>
                <qrcode-vue v-show="false" id="qrcode-png" :value="eventData && eventData.link_code" :size="600" level="H" />
                <qrcode-vue v-show="false" id="qrcode-png-interactive" :value="eventData && eventData.link_code ? `${eventData.link_code}?is_interactive=1` : ''" :size="600" level="H" />
              <div class="">{{ $t('Download QR code') }}</div>
            </div>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
      <vs-dropdown style="z-index: 9999" class="dd-actions cursor-pointer" vs-trigger-click>
        <div>
          <vs-button class="ml-2 btn-icon px-3 py-2 pointer" color="secondary" type="border">
            <div class="flex items-center text-bold">
              {{ $t('View') }}
              <svg class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <title>chevron-down</title>
                <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
              </svg>
            </div>
          </vs-button>
        </div>
        <vs-dropdown-menu>
          <!-- Present new tab -->
          <vs-dropdown-item @click="presentLinkWithParticipant()">
            <div class="flex items-center view-menu">
              <div>
                <svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <title>open-in-new</title>
                  <path
                    d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z" />
                </svg>
              </div>
              <div class="ml-2">{{ $t('Present in a new tab') }}</div>
            </div>
          </vs-dropdown-item>
          <!-- Present full screen -->
          <!-- Copy present link -->
          <vs-dropdown-item @click="copyPresentLinkToClipboard()">
            <div class="flex items-center view-menu">
              <div>
                <svg class="w-6 pt-1 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <title>content-copy</title>
                  <path
                    d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" />
                </svg>
              </div>
              <div class="ml-2">{{ $t('Copy Present link') }}</div>
            </div>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
      </div>
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full" :style="!isPollEnabled ? 'pointer-events: none; cursor: not-allowed' : ''">
        <div class="col-12" v-if="!isFetching">
          <ListPolls :eventData="eventData" ref="listPolls"></ListPolls>
        </div>
        <div class="col-6" v-if="!isFetching">
          <LivePolls :eventData="eventData" ref="livePolls"></LivePolls>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import eventsApi from '@/api/event';
import LivePolls from '@/components/polls/LivePolls.vue';
import ListPolls from '@/components/polls/ListPolls.vue';
import QrcodeVue from 'qrcode.vue';
import JSZip from 'jszip';

export default {
  name: 'EventQnA',
  components: {
    LivePolls,
    ListPolls,
    QrcodeVue,
  },
  data() {
    return {
      isFetching: false,
      eventData: null,
      room: null,
      isPollEnabled: false,
    };
  },
  sockets: {
    connect() {
      this.initSocket();
    },
    voting_reset() {
      this.resetAllVoting();
    },
  },
  methods: {
    resetAllVoting() {
      this.$refs.livePolls.resetAll();
      this.$refs.listPolls.resetAll();
    },
    initSocket() {
      if (this.room) {
        this.$socket.client.emit('join', this.room);
      }
    },
    presentLinkWithParticipant() {
      const url = `${process.env.VUE_APP_APP_URL}/events/${this.slug}/display`;
      window.open(url, '_blank');
    },
    copyPresentLinkToClipboard() {
      const url = `${process.env.VUE_APP_APP_URL}/events/${this.slug}/display`;
      // Copy the text inside the text field
      navigator.clipboard.writeText(url);
      this.$vs.notify({
        text: 'Present Link copied to clipboard',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success',
      });
    },
    leaveSocketRoom() {
      this.$socket.client.emit('leave', this.room);
    },
    fetchDetails() {
      this.isFetching = true;
      this.$vs.loading();
      const callback = (response) => {
        const event = response.data;
        this.eventData = event;
        const eventId = this.eventData ? this.eventData.id : '';
        this.room = `event_${eventId}`;
        this.initSocket();
        const settings = this.eventData && this.eventData.settings ? JSON.parse(this.eventData.settings) : null;
        if (settings) this.isPollEnabled = settings && settings.polls && settings.polls.is_enabled ? settings.polls.is_enabled : false;
        this.isFetching = false;
        document.title = event.name;
        this.$vs.loading.close();
      };
      const errorCallback = () => {
        this.isFetching = false;
        this.$vs.loading.close();
      };
      eventsApi.getBySlug(this.slug, callback, errorCallback);
    },
    downloadCanvasQR() {
      // Get the parent div elements using selectors
      const parentDivInteractive = document.getElementById('qrcode-png-interactive');
      const parentDivStatic = document.getElementById('qrcode-png');

      // Check if the parent div elements are found
      if (!parentDivInteractive || !parentDivStatic) {
        console.error('One or both parent div elements not found');
        return;
      }

      // Find the canvas elements within the parent divs
      const canvasInteractive = parentDivInteractive.querySelector('canvas');
      const canvasStatic = parentDivStatic.querySelector('canvas');

      // Check if the canvas elements are found
      if (!canvasInteractive || !canvasStatic) {
        console.error('One or both canvas elements not found within the parent divs');
        return;
      }

      // Create a JSZip instance
      const zip = new JSZip();

      // Convert the canvases to data URLs representing the PNG images
      const dataUrlInteractive = canvasInteractive.toDataURL('image/png');
      const dataUrlStatic = canvasStatic.toDataURL('image/png');

      // Add the data URLs to the zip file with specified filenames
      zip.file('Event Page.png', dataUrlStatic.split('base64,')[1], { base64: true });
      zip.file('URL for Interactive Only.png', dataUrlInteractive.split('base64,')[1], { base64: true });

      // Generate the zip file
      zip.generateAsync({ type: 'blob' })
        .then((blob) => {
          // Create an anchor element
          const a = document.createElement('a');

          // Set the href attribute to the generated zip file
          a.href = URL.createObjectURL(blob);

          // Set the download attribute with the desired filename
          a.download = 'kobla-qr-codes.zip';

          // Append the anchor element to the document
          document.body.appendChild(a);

          // Trigger a click on the anchor element to start the download
          a.click();

          // Remove the anchor element from the document
          document.body.removeChild(a);
        })
        .catch((error) => {
          console.error('Error generating zip file:', error);
        });
    },
    shareLinkQuestion() {
      const routeData = this.$router.resolve({
        name: 'EventLive',
        params: {
          eventLinkCode: this.linkCodeValue,
        },
      });
      const targetUrl = `${routeData.href}?is_interactive=1`;
      window.open(targetUrl, '_blank');
    },
    shareLinkVideo() {
      const routeData = this.$router.resolve({
        name: 'EventLive',
        params: {
          eventLinkCode: this.linkCodeValue,
        },
      });
      const targetUrl = `${routeData.href}`;
      window.open(targetUrl, '_blank');
    },
  },
  computed: {
    slug() {
      return this.$route.params.eventSlug;
    },
    linkCodeValue() {
      const linkCode = this.eventData && this.eventData.link_code_value ? this.eventData.link_code_value : '';
      return linkCode;
    },
  },
  created() {
    this.fetchDetails();
  },
  beforeDestroy() {
    this.leaveSocketRoom();
  },
};

</script>
<style>
  .top-bar-qna {
    margin-right: 220px;
    position: absolute;
  }

  @media screen and (max-width: 1200px) {
    .top-bar-qna {
      position: fixed;
      height: 80px;
      z-index: 9999;
    }
  }

  @media screen and (max-width: 575px) {
    .top-bar-qna {
      margin-right: 80px;
    }
  };

  .share-menu {
    min-width: 140px;
  }

  .view-menu {
    min-width: 160px;
  }

</style>
